import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import './Navbar.css';
import logo from './omgal.png';
import logox from './telematics.png';
import { FaTwitter, FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa';
import { MdLanguage } from 'react-icons/md';
import { useLanguage } from './LanguageContext';

const Navbar = ({ handleItemClick }) => {
    const { language, toggleLanguage } = useLanguage();
    const [submenuOpen, setSubmenuOpen] = useState(null);
    const [clickedItem, setClickedItem] = useState('');

    const handleMouseEnter = (index) => {
        if (!clickedItem) {
            setSubmenuOpen(index);
        }
    };
    
    const handleMouseLeave = () => {
        if (!clickedItem) {
            setSubmenuOpen(null);
        }
    };

    const handleClick = (item) => {
        handleItemClick(item);

        setSubmenuOpen(null); // Alt menüyü kapat
    };

    const handleMenuClick = () => {
        setSubmenuOpen(null);
    };

    

    return (
        <nav className="navbar">
            <img src={logo} alt="Logo" className="navbar-logo" />
            <ul className="navbar-links">
                <li className="navbar-item" onClick={handleMenuClick} onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave}>
                    <Link to="home" smooth={true} duration={1000}>{language === 'tr' ? 'ANASAYFA' : 'HOME'}</Link>
                </li>
                <li className="navbar-item"  onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave}>
                    <Link to="about" smooth={true} duration={1000}>{language === 'tr' ? 'HAKKIMIZDA' : 'ABOUT'}</Link>
                    <ul className={`submenu ${submenuOpen === 1 ? 'open' : ''}`} onMouseLeave={() => setSubmenuOpen(null)}>

                        <li><a href="#">{language === 'tr' ? 'Vizyon & Misyon' : 'Vision & Mission'}</a></li>
                        <li><a href="#" onClick={() => handleClick('Neden Biz?')}>{language === 'tr' ? 'Neden Biz?' : 'Why U.S?'}</a></li>
                        <li><a href="#" onClick={() => handleClick('Kurumsal')}>{language === 'tr' ? 'Kurumsal' : 'Corporate'}</a></li>
                    </ul>
                </li>
                <li className="navbar-item"  onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={handleMouseLeave}>
                    <Link to="services" smooth={true} duration={1000}>{language === 'tr' ? 'ÇÖZÜMLERİMİZ' : 'SOLUTIONS'}</Link>
                    <ul className={`submenu ${submenuOpen === 2 ? 'open' : ''}`} onMouseLeave={() => setSubmenuOpen(null)}>
                        {/* <li><Link to="services" smooth={true} duration={1000} onClick={() => handleItemClick('productPdf')}>{language === 'tr' ? 'Ürün Broşürleri' : 'Product Brochures'}</Link></li> */}
                        <li><Link to="services" smooth={true} duration={1000} onClick={() => handleItemClick('independentIotPlatform')}>{language === 'tr' ? 'Neden Bağımsız IoT Platformu ? ' : 'Why an Independent IoT Platform?'}</Link></li>
                        <li><Link to="services" smooth={true} duration={1000} onClick={() => handleItemClick('isaSupportedSystems')}>{language === 'tr' ? 'ISA Destekli Sistemler' : 'ISA Supported Systems'}</Link></li>
                        <li><Link to="services" smooth={true} duration={1000} onClick={() => handleItemClick('telematicsProjects')}>{language === 'tr' ? 'Telematik Projeleri' : 'Telematics Projects'}</Link></li>
                        <li><Link to="services" smooth={true} duration={1000} onClick={() => handleItemClick('customCanBusProjects')}>{language === 'tr' ? 'Özel CAN-BUS Projeleri' : 'Custom CAN-BUS Projects'}</Link></li>
                    </ul>
                </li>
                <li className="navbar-item"  onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={handleMouseLeave}>
                    <Link to="contact" smooth={true} duration={1000}>{language === 'tr' ? 'İLETİŞİM' : 'CONTACT'}</Link>
                    <ul className={`submenu ${submenuOpen === 3 ? 'open' : ''}`} onMouseLeave={() => setSubmenuOpen(null)}>
                        <li><Link to="contact" smooth={true} duration={1000}>{language === 'tr' ? 'İletişim Adreslerimiz' : 'Our Contact Information'}</Link></li>
                        <li><a href="#">{language === 'tr' ? 'Bize Ulaşın' : 'Contact U.S'}</a></li>
                    </ul>
                </li>
            </ul>
            <div className="navbar-icons">
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <FaTwitter className="navbar-icon" />
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="navbar-icon" />
                </a>
                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                    <FaYoutube className="navbar-icon" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="navbar-icon" />
                </a>
            </div>
            <img src={logox} alt="Logox" className="navbar-logox" onClick={() => window.location.href = 'http://88.198.1.166:4203'} />
            <div className="navbar-language" onClick={toggleLanguage}>
                <MdLanguage className="navbar-icon" />
                {language === 'tr' ? 'Türkçe' : 'English'}
            </div>
        </nav>
    );
};

export default Navbar;
