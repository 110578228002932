import React, { useState, useEffect } from 'react';

import './Home.css';
import cloudsVideo1 from './backgrnd_1.mp4'; // Video paths for each banner
import cloudsVideo2 from './carmatrix.mp4';
import cloudsVideo3 from './backgrnd_3.mp4';
import cloudsVideo4 from './adasis.mp4';
import cloudsVideo5 from './backgrnd_6.mp4';
import cloudsVideo6 from './clouds.mp4';
import { useLanguage } from './LanguageContext'; // Dil bağlamını içeri aktar

const Home = () => {
    const { language } = useLanguage(); // Dil bağlamından mevcut dil bilgisini al
    const [currentBanner, setCurrentBanner] = useState(0); // State to track current banner
    const [isPlaying, setIsPlaying] = useState(true); // Başlangıçta oynatılıyor
    const [intervalId, setIntervalId] = useState(null);
    
    // Banner data with video paths, welcome text, and subtext
    const banners = [
        {
            video: cloudsVideo4,
            effect: false, // Efektsiz stil için bayrak
            duration: 38700
        },
        {
            video: cloudsVideo5,
            welcomeText: language === 'tr' ?
                (<div style={{ marginTop: '3em' }}><h1>Araç İçi IoT Telematik Sistemleri: Araçlarınızı Akıllı Hale Getirin</h1></div>) :
                (<div style={{ marginTop: '3em' }}><h1>In-Vehicle IoT Telematics Systems: Make Your Vehicles Smart.</h1></div>),
            subText: language === 'tr' ?
                (<div style={{ marginTop: '3em' }}><p>Otomotiv Ana Sanayisi için IoT Telematik Sistemleri, <br />
                    <br />Araçlarınızı akıllı hale getirmek ve endüstri ihtiyaçlarını karşılamak için tasarlanmış yenilikçi çözümler sunar. <br />
                    <br />Temel özelliğimiz, CanBus üzerinden araç verilerini okuyabilme yeteneğiyle donatılmış telematik cihazlarımız <br />
                    <br />Ve yapay zeka ile donatılmış yazılımlarımızdır.</p> </div>)
                : (
                    <div style={{ marginTop: '3em' }}> <p>IoT Telematics Systems for the Automotive Industry offer innovative solutions designed to <br />
                        <br />make your vehicles smarter and meet industry needs. <br />
                        <br />Our key feature includes telematics devices equipped with the ability to <br />
                        <br />read vehicle data via CanBus and our software enhanced with artificial intelligence.</p></div>),
            effect: false, // Efektsiz stil için bayrak
            duration: 14000 // 5 saniye
        },
        {
            video: cloudsVideo2,
            welcomeText: language === 'tr' ?
                (<div style={{ marginTop: '3em' }}><h1>Modüler On Board Unit: V2X Altyapısının Ayrılmaz Parçası</h1></div>) :
                (<div style={{ marginTop: '3em' }}><h1>Modular On Board Unit: Integral Part of V2X Infrastructure</h1></div>),
            subText: language === 'tr' ?
                (<div style={{ marginTop: '3em' }}><p>©  Cihazlarımız, modüler on board unit özelliğiyle V2X altyapısının ayrılmaz bir parçası olacak.  <br />
                    <br />Akıllı RSU tarafının önemli bir bileşeni olarak,<br />
                    <br />iletişim ağınızı güçlendirerek daha akıllı ve güvenli bir yolculuk için geliştirilmeye hazır modüler yeni nesil bir OBU sunuyoruz.<br />
                    <br />Deneyimli uzmanlarımızla, en yeni ve yenilikçi çözümleri sunarak sektörde bir adım öne çıkıyoruz.<br />
                    <br />Otomotiv endüstrisindeki teknolojik dönüşümü liderleştiren bir ekip olarak hareket ediyoruz. </p> </div>)
                : (<div style={{ marginTop: '3em' }}><p>©  Our devices will be an integral part of the V2X infrastructure with modular on-board unit feature. <br />
                    <br />As a significant component of the Smart RSU side,<br />
                    <br />we offer a modular next-generation OBU ready for development to strengthen your communication network for a smarter and safer journey. <br />
                    <br />With our experienced experts, we stand out in the industry by offering the latest and most innovative solutions. <br />
                    <br />We act as a team leading the technological transformation in the automotive industry.</p> </div>)
            ,
            effect: false, // Efektsiz stil için bayrak
            duration: 13000 // 5 saniye
        },

        {
            video: cloudsVideo3,
            welcomeText: language === 'tr' ? (<div><h1>Yeni Ürünümüz , "ADV2"</h1></div>) : (<div><h1>"ADV2", Our New Product</h1></div>),
            subText: language === 'tr' ? (<p>Detaylar için tıklayın..</p>) : (<p>Click for details..</p>),
            effect: true, // Efektli stil için bayrak
            duration: 8000 // 5 saniye
        },

        {
            video: cloudsVideo1,
            welcomeText: language === 'tr' ?
                (
                    <div style={{ marginTop: '3em' }}><h1>Otomotiv Endüstrisinin Geleceğini Şekillendiriyoruz</h1></div>) :
                (<div style={{ marginTop: '3em' }}><h1>We are Shaping the Future of the Automotive Industry.</h1></div>),
            subText: language === 'tr' ?
                (
                    <div style={{ marginTop: '3em' }}> <p>Otomotiv sektöründe devrim yaratan teknolojilerin kapıları aralanıyor. <br />
                        <br />Otomotiv endüstrisinin gelişen dinamikleri içinde öncü bir rol üstleniyoruz <br />
                        <br />Ve araçlarınızı akıllı hale getirmek için en yenilikçi çözümleri sunuyoruz. <br />
                        <br />Ar-Ge ekiplerimizin geliştirdiği ve tüm lisansların bize ait olduğu donanımlarımız ve yazılımlarımız ile, <br />
                        <br />Üreticilerin ihtiyaç ve taleplerine göre yazılım alt yapılarını butik olarak tasarlayabiliyoruz.</p></div>)
                : (
                    <div style={{ marginTop: '3em' }}> <p>The doors are opening to revolutionary technologies in the automotive sector. <br />
                        <br />We are taking a pioneering role in the evolving dynamics of the automotive industry, <br />
                        <br />offering the most innovative solutions to make your vehicles smarter. <br />
                        <br />With our hardware and software, developed by our R&D teams and all licensed to us, <br />
                        <br />we can tailor software infrastructures to the needs and demands of manufacturers in a boutique manner.</p></div>),
            effect: false,
            duration: 12000 // 5 saniye // Efektsiz stil için bayrak
        }
        
        

    ];

    // Function to handle click on the welcome text or subtext
    const handleClick = () => {
     /*    const navbarHeight = 100; // Navbar yüksekliği
        const padding = 100; // Ekstra boşluk

        const topOffset = navbarHeight + padding; // Navbar yüksekliği ve ekstra boşluğun toplamı

        window.scrollTo(0, topOffset); */
        window.open(`${process.env.PUBLIC_URL}/advrbr.jpeg`, '_blank');

    };

    // Function to change banner
    const changeBanner = () => {
        let nextBanner = currentBanner + 1;
        if (nextBanner === banners.length) {
            nextBanner = 0; // İlk bannera dön
        }
        setCurrentBanner(nextBanner);
        if (nextBanner === 0) {
            setIsPlaying(false); // Son banner oynatıldığında oynatmayı durdur
        }
    };



    useEffect(() => {
        console.log('Current banner:', currentBanner, 'Is playing:', isPlaying);
        let id;
        if (isPlaying) {
            id = setInterval(changeBanner, banners[currentBanner].duration);
            setIntervalId(id);
        }
        return () => clearInterval(id); // Doğru değişken olan id'yi kullanın
    }, [currentBanner, isPlaying]);

    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <section id="home" className="home-section">
            <video className="background-video" autoPlay loop muted key={banners[currentBanner].video}>
                <source src={banners[currentBanner].video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="home-content">
                <div onClick={handleClick} className={banners[currentBanner].effect ? 'effect' : 'no-effect'}>
                    {banners[currentBanner].welcomeText}
                </div>
                <div onClick={handleClick} className={banners[currentBanner].effect ? 'effect' : 'no-effect'}>
                    {banners[currentBanner].subText}
                </div>
                <button
                    onClick={togglePlayPause}
                    className={`play-pause-button ${isPlaying ? 'playing' : 'paused'}`}
                >
                    {isPlaying ? 'Pause Banner' : 'Play Banner'}
                </button>

            </div>
            {currentBanner === 3 && (
                <div className="image-wrapper">
                    <img src="./adv2_2.png" alt="Your Image" />
                </div>
            )}
        </section>

    );
};

export default Home;
