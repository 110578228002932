import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => {
    return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('tr'); // Başlangıçta Türkçe olarak ayarlanmış dil

    const toggleLanguage = () => {
        setLanguage(language === 'tr' ? 'en' : 'tr'); // Dil değişimini gerçekleştir
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageContext;
