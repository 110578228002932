import React from 'react';
import './Services.css';
import PdfViewer from './PdfViewer';

import cloudsVideo from './clouds.mp4'; // Videonuzun doğru yolunu belirtin
import { useLanguage } from './LanguageContext'; // Dil bağlamını içeri aktar

let content = null;

const Services = ({ clickedItem }) => {
    const { language } = useLanguage(); // Dil bağlamından mevcut dil bilgisini al

    const getContent = () => {
        if (!clickedItem) {
            return (
                <>
                    {language === 'tr' ? (
                        <>
                            <div style={{ marginTop: '1em' }}>
                                <h2>YENİ NESİL TEKNOLOJİLER</h2>
                            </div>
                            <div style={{ marginTop: '2em' }}>
                                <p>
                                    ©  ISA DESTEKLİ ECU ÜRETİMİ <br /><br />
                                    ©  ÖZEL TASARIM TELEMATİK DONANIM VE YAZILIMLARI <br /><br />
                                    ©  ÖZEL TASARIM NANO TEKNOLOJİ DONANIM ÜRETİMİ<br /><br />
                                    ©  MOBİL KONTROL-TAKİP-ÖLÇÜM SİSTEMLERİ DONANIM VE YAZILIMLARI<br /><br />
                                    ©  LoRa™ - SigFox™ - RF DESTEKLİ IoT & SCADA ÇÖZÜMLERİ<br /><br />
                                    ©  AKILLI ŞEHİRCİLİK DONANIM VE YAZILIMLARI<br /><br />
                                    ©  GİYİLEBİLİR SAĞLIK TEKNOLOJİLERİ<br /><br />
                                    ©  ÖZEL SENSÖR ÜRETİMİ<br /><br />
                                    ©  YAPAY ZEKA, DERİN ÖĞRENME VE MAKİNE ÖĞRENMESI ALGORİTMALARI TASARIMI<br /><br />
                                    ©  LİNEER OPTİMİZASYON ALGORİTMALARI TASARIMI<br /><br />
                                    ©  ENDÜSTRİYEL TASARIM VE DANIŞMANLIK<br /><br />
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ marginTop: '3em' }}>
                                <h2>NEW GENERATION TECHNOLOGIES</h2>
                            </div>
                            <div style={{ marginTop: '3em' }}>
                                <p>
                                    © ISA Supported ECU Production <br /><br />
                                    © Special Design Telematics Hardware and Software <br /><br />
                                    © Special Design Nano Technology Board Production <br /><br />
                                    © Mobile Control-Tracking-Measurement Systems Hardware and Software <br /><br />
                                    © LoRa™ - SigFox™ - RF Supported IoT & SCADA Solutions <br /><br />
                                    © Smart City Hardware and Software <br /><br />
                                    © Wearable Health Technologies <br /><br />
                                    © Special Sensor Production <br /><br />
                                    © Artificial Intelligence, Deep Learning, and Machine Learning Algorithm Design <br /><br />
                                    © Linear Optimization Algorithm Design <br /><br />
                                    © Industrial Design and Consultancy
                                </p>
                            </div>
                        </>
                    )}
                </>
            );
        }

        switch (clickedItem) {
            case 'isaSupportedSystems':
                return language === 'tr' ? 
                    (<div style={{ marginTop: '2em' }}>
                        <h2>ISA Destekli Sistemler</h2>
                        <p>
                            ©  ISA DESTEKLİ ECU ÜRETİMİ <br /><br />
                            ...
                        </p>
                    </div>) :
                    (<div style={{ marginTop: '2em' }}>
                        <h2>ISA Supported Systems</h2>
                        <p>
                            © ISA Supported ECU Production <br /><br />
                            ...
                        </p>
                    </div>);
            /* case 'productPdf':
                content = <PdfViewer pdfUrl="web3.pdf" />;
                break; */
            case 'telematicsProjects':
                return language === 'tr' ? 
                    (<div style={{ marginTop: '2em' }}>
                        <h2>Telematik Projeleri</h2>
                        <p>
                            ©  ÖZEL TASARIM TELEMATİK DONANIM VE YAZILIMLARI <br /><br />
                            ...
                        </p>
                    </div>) :
                    (<div style={{ marginTop: '2em' }}>
                        <h2>Telematics Projects</h2>
                        <p>
                            © Special Design Telematics Hardware and Software <br /><br />
                            ...
                        </p>
                    </div>);
            case 'customCanBusProjects':
                return language === 'tr' ? 
                    (<div style={{ marginTop: '2em' }}>
                        <h2>Özel CAN-BUS Projeleri</h2>
                        <p>
                            ©  ÖZEL CAN-BUS PROJELERİ <br /><br />
                            ...
                        </p>
                    </div>) :
                    (<div style={{ marginTop: '2em' }}>
                        <h2>Custom CAN-BUS Projects</h2>
                        <p>
                            © Custom CAN-BUS Projects <br /><br />
                            ...
                        </p>
                    </div>);
            case 'independentIotPlatform':
                return language === 'tr' ? 
                    (<div style={{ marginTop: '2em' }}>
                    <h2>NEDEN BAĞIMSIZ IoT PLATFORMU?</h2>
                    <p>
                         <br />© Bağımsız IoT Platformu :<br /><br />
                        Dinamik açık kaynaklı platformlar: Bağımsız IoT platformunu tercih etmemizin nedeni, tüm yazılım kaynağını kendi Ar-Ge ekibimizle birlikte proje özelinde geliştirebilmemizdir.
                    </p>
                    <p>
                        Bu yaklaşım sayesinde, her proje için ölçeğine uygun butik yazılım alt yapıları oluşturabiliriz. Ekibimiz, cihazlar arası iletişim altyapıları konusunda geniş bir uzmanlığa sahiptir.
                    </p>
                    <p>
                        Wi-Fi, Bluetooth, NFC, RFID, LoRa, LTE-M, Sigfox gibi iletişim araçlarına hakimken aynı zamanda GPS, M2M, DATA gibi Macro istasyonlu iletişim teknolojilerinde de deneyimlidir. 
                    </p>
                    <p>
                        İşlemci tabanında çalışan AR-GE ekibimiz, özellikle MediaTek işlemcilerde yılların tecrübesi ile yüksek bir deneyime sahiptir.
                    </p>
                    <p>
                        Bu deneyim sayesinde, donanım tabanlı projelerde özel bir uzmanlık alanı geliştirmiş bulunmaktayız.
                    </p>
                </div>) :
                    (<div style={{ marginTop: '2em' }}>
                    <h2>Independent IoT Platform</h2>
                    <p>
                        © Why an Independent IoT Platform? <br /><br />
                        Dynamic open-source platforms: The reason we prefer an independent IoT platform is that we can develop all the software resources together with our own R&D team specifically for the project.
                    </p>
                    <p>
                        With this approach, we can create boutique software infrastructures suitable for the scale of each project. Our team has extensive expertise in inter-device communication infrastructures.
                    </p>
                    <p>
                        While they are proficient in communication tools such as Wi-Fi, Bluetooth, NFC, RFID, LoRa, LTE-M, and SigFox, they also have experience in macro station communication technologies such as GPS, M2M, and DATA.
                    </p>
                    <p>
                        Our R&D team, working at the CPU level, has significant experience particularly with MediaTek processors.
                    </p>
                    <p>
                        Thanks to this experience, we have developed a special expertise in hardware-based projects.
                    </p>
                </div>);
            default:
                return null;
        }
    };

    return (
        <section id="services" className="services-section">
            <div>{content}</div>
            <video className="background-video" autoPlay loop muted>
                <source src={cloudsVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="blur-background">
                {getContent()}
            </div>
        </section>
    );
};

export default Services;
