import React from 'react';
import './About.css';
import cloudsVideo from './backgrnd_1.mp4';
import { useLanguage } from './LanguageContext';

const About = ({ clickedItem }) => {
    const { language } = useLanguage();

    const welcomeText = clickedItem === 'Kurumsal'
        ? language === 'tr'
            ? <div><h1>Grup Şirketimiz OMGAL Hakkında..</h1></div>
            : <div><h1>About Our Group Company, OMGAL</h1></div>
        : language === 'tr'
            ? <div><h1>Neden Biz?</h1></div>
            : <div><h1>Why U.S?</h1></div>;

    const subText = clickedItem === 'Kurumsal'
        ? language === 'tr'
            ? <div>
                <p class="indent-paragraph">2000'li yılların ortalarından bugüne kadar kamu alanında faaliyet gösteren şirketler topluluğumuzun bünyesinde bulunan OMGAL Teknoloji,</p> 
                <p>(Otomotiv Mühendisliği Geliştirme Algoritmalari) merkezi İstanbul Fatih'te yer almaktadır.</p><br />
                <p class="indent-paragraph">Grup şirketlerinin ihtiyaçları doğrultusunda ortaya çıkan yazılım ve donanım hizmetlerini son beş yıldır kamu ve özel sektöre de açarak portföyünü genişletmiştir.</p><br />
                <p class="indent-paragraph">Giyilebilir yazılımlar ve varlık yönetimi yapabilen donanımlar üretmeyi prensip edinmiş olan OMGAL Teknoloji, bu kapsamda Teknopark İstanbul'da bulunan AR-GE biriminde </p>
                <p>yerli mühendis kadrosuyla faaliyet göstermektedir.</p><br />
                <p class="indent-paragraph">Tamamen yerli sermaye desteği ile yerli ve milli yazılımlar üreten OMGAL Teknoloji, kurumların ihtiyaçlarına göre yerli donanım tasarımları üretmektedir.</p><br />
                <p class="indent-paragraph">OMGAL Teknoloji; kamu kurumları, otomotiv sanayisi, savunma sanayisi olmak üzere üç farklı sektöre uçtan uca yazılım ve donanım üreterek, dijital entegratör firma konumundadır.</p><br />
                <p class="indent-paragraph">Giyilebilir teknolojiler konusunda kurumlara butik yazılım çözümleri sağlayan OMGAL Teknoloji, yurt içinde ve Avrupa'da bulunan çözüm ortaklarının yanı sıra </p>
                <p>proje bazlı iş ortakları ile geniş bir kurumsal coğrafyada hizmet vermektedir.</p>
              </div>
            : <div>
                <p>Since the mid-2000s, OMGAL Technology (Automotive Engineering Development Algorithms), located in Istanbul Fatih, has been operating within our group of companies active in the public sector.</p>
                <p>In line with the needs of group companies, it has expanded its portfolio by providing software and hardware services to both the public and private sectors for the last five years.</p>
                <p>OMGAL Technology, which is committed to producing wearable software and asset management capable hardware, operates with its local engineering team in the R&D unit located in Teknopark Istanbul.</p>
                <p>OMGAL Technology, which produces local and national software with the support of entirely domestic capital, designs local hardware according to the needs of institutions.</p>
                <p>OMGAL Technology is a digital integrator company that produces end-to-end software and hardware for three different sectors: public institutions, automotive industry, and defense industry.</p>
                <p>OMGAL Technology, which provides boutique software solutions to institutions in the field of wearable technologies, offers services in a wide corporate geography with its solution partners in Turkey and Europe, as well as project-based business partners.</p>
              </div>
        : language === 'tr'
            ? <div>
                <h2>Otomotiv Ana Sanayii Standartlarına Uyum:</h2>
                <p>Telematik cihazlarımız, otomotiv ana sanayisinin belirlediği kalite ve güvenlik standartlarını karşılayacak alt yapıya sahiptir.</p>
                <p>Her üreticinin talebine göre, uygun sertifikalara haiz şekilde üretim yapabilmekteyiz.</p>
                <p>Avrupa Birliği standartları olan "General Safety Regulation" alt başlıklarına uyumlu bir donanım alt yapısı sunabiliyoruz.</p>
                <p>Kapsam içinde R141, R155-156 gibi uyumluluklara uyumluluk gösterecek teknolojik alt yapısı mevcuttur.</p>
                <h2>Geniş Veri İzleme Kapasitesi:</h2>
                <p>Donanım ve yazılımlarımız, araçlarınızın performansını, konumunu ve güvenliğini izlemenize olanak tanıyan kapsamlı veri izleme özelliklerine sahiptir.</p>
                <p>Motor verilerinden sürüş alışkanlıklarına kadar geniş bir yelpazede veri sağlayabiliyoruz.</p>
                <h2>Gelişmiş Güvenlik ve İzleme:</h2>
                <p>Telematik sistemimiz, araçların çalınması gibi risklere karşı etkili bir koruma sağlar.</p>
                <p>Anormal durumlar algılandığında hızlı ve doğru bildirimler almanızı sağlar.</p>
                <h2>Esnek Entegrasyon ve Özelleştirme:</h2>
                <p>Sistemimiz, farklı otomotiv markaları ve modelleriyle uyumlu şekilde tasarlanmıştır.</p>
                <p>Ayrıca, ana sanayinin talep ettiği özelleştirme ve sanayi spesifik gereksinimlere uyum sağlama konusunda esneklik sunar.</p>
              </div>
            : <div>
                <h2>Compliance with Automotive Industry Standards:</h2>
                <p>Our telematics devices are equipped with infrastructure that meets the quality and safety standards set by the automotive industry.</p>
                <p>We can produce in compliance with the appropriate certifications according to the request of each manufacturer.</p>
                <p>We can provide a hardware infrastructure compliant with European Union standards such as the General Safety Regulation.</p>
                <p>It has a technological infrastructure that will comply with compatibilities such as R141, R155-156 within the scope.</p>
                <h2>Extensive Data Monitoring Capacity:</h2>
                <p>Our hardware and software have comprehensive data monitoring features that allow you to monitor the performance, location, and safety of your vehicles.</p>
                <p>We can provide a wide range of data from engine data to driving habits.</p>
                <h2>Advanced Security and Monitoring:</h2>
                <p>Our telematics system provides effective protection against risks such as vehicle theft.</p>
                <p>It enables you to receive fast and accurate notifications when abnormal situations are detected.</p>
                <h2>Flexible Integration and Customization:</h2> 
                <p>Our system is designed to be compatible with different automotive brands and models.</p>
                <p>Additionally, it offers flexibility in customizing to meet the demands of the main industry and specific industry requirements.</p>
              </div>;

    return (
        <section id="about" className="about-section">
            <video className="background-video" autoPlay loop muted>
                <source src={cloudsVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {welcomeText}
            {subText}
        </section>
    );
};

export default About;
