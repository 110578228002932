import React, { useEffect, useState } from 'react';
import './Footer.css';

const Footer = () => {
    
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1000) {
                setIsVisible(true);
                const timer = setTimeout(() => setIsVisible(false), 3000);
                return () => clearTimeout(timer);
            }
            setIsVisible(false);
        };

        const handleMenuClick = () => {
            setIsVisible(true);
            const timer = setTimeout(() => setIsVisible(false), 3000);
            return () => clearTimeout(timer);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleMenuClick);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleMenuClick);
        };
    }, []);

    return (
        <footer className={`footer ${isVisible ? 'visible' : 'hidden'}`}>
            <div className="footer-content">
                <img src="./omgalt.png" alt="Logo" className="footer-logo" />
                <p>&copy; 2010-2024 OMGAL Teknoloji  |  Tüm hakları saklıdır.  |  KVKK Metni</p>
            </div>
        </footer>
    );
};

export default Footer;
