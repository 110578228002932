import React, { startTransition, useState } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
/* import ContactForm from './components/ContactForm'; */
import Footer from './components/Footer';
import './App.css';
import { LanguageProvider } from './components/LanguageContext';

const App = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clickedItem, setClickedItem] = useState('');
  
  const handleItemClick = (item) => {
    setClickedItem(item);
    
  };
  

  /* const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://example.com/data');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const newData = await response.json();
      setData(newData);
      setError(null); // Clear any previous errors
    } catch (error) {
      setError('Error fetching data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }; */

  return (
    <LanguageProvider>
      <div>
        <Navbar handleItemClick={handleItemClick}/>

        <About clickedItem={clickedItem} />
        <Home />
        <Services clickedItem={clickedItem} />
        <Contact />
        {/* <ContactForm /> */}
        <Footer />
      </div>

    </LanguageProvider>

  );
};

export default App;
