import React from 'react';
import './Contact.css';
import ContactForm from './ContactForm';
import backgroundVideo from './clouds.mp4'; // Replace with the correct path to your video
import { useLanguage } from './LanguageContext'; // Dil bağlamını içeri aktar


const Contact = () => {
    const { language } = useLanguage(); // Dil bağlamından mevcut dil bilgisini al

    const content = {
        tr: {
            title: "İletişim",
            office: "Merkez Ofis",
            address: "Adres: Akşemsettin Mh. Alb.Cemil Sakarya Sk.20, Fatih / İstanbul",
            phone: "Telefon: (+90) 0212 465 58 46",
            email: "Email: info@omgal.com.tr",
            techOffice: "TeknoPark Ar-Ge Ofis",
            techAddress: "Adres: İstanbul TeknoPark  4/A NO: 1/4, Pendik / İstanbul",
            ankaraOffice: "Ankara Ofis",
            ankaraAddress: "Adres: Beytepe Mh. 1644 Sk. No:19, Çankaya / Ankara",
            ankaraPhone: "Telefon: (+90) 0212 465 58 46",
        },
        en: {
            title: "Contact",
            office: "Head Office",
            address: "Address: Akşemsettin Mh. Alb.Cemil Sakarya Sk.20, Fatih / Istanbul",
            phone: "Phone: (+90) 0212 465 58 46",
            email: "Email: info@omgal.com.tr",
            techOffice: "TechnoPark R&D Office",
            techAddress: "Address: Istanbul TechnoPark 4/A NO: 1/4, Pendik / Istanbul",
            ankaraOffice: "Ankara Office",
            ankaraAddress: "Address: Beytepe Mh. 1644 Sk. No:19, Cankaya / Ankara",
            ankaraPhone: "Phone: (+90) 0212 465 58 46",
        }
    };

    const { title, office, address, phone, email, techOffice, techAddress, ankaraOffice, ankaraAddress, ankaraPhone } = content[language];

    return (
        <section id="contact" className="contact-section">
            <video className="background-video" autoPlay loop muted>
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <h2>{title}</h2>
            <div className="contact-info">
                <div className="contact-item">
                    <h3>{office}</h3>
                    <p>{address}</p>
                    <p>{phone}</p>
                    <p>{email}</p>
                </div>
                <div className="contact-item">
                    <h3>{techOffice}</h3>
                    <p>{techAddress}</p>
                    <p>{phone}</p>
                    <p>{email}</p>
                </div>
                <div className="contact-item">
                    <h3>{ankaraOffice}</h3>
                    <p>{ankaraAddress}</p>
                    <p>{ankaraPhone}</p>
                    <p>{email}</p>
                </div>
            </div>
            <div className="map-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3090.5767036475787!2d28.93920398010864!3d41.017254222672236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba8eb6b6b1d9%3A0x99e8df3d4c4c21d4!2s41.017254222672236%2C%2028.941292230099705!5e0!3m2!1sen!2str!4v1624627350343!5m2!1sen!2str"
                    width="800"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Map"
                ></iframe>
            </div>
        </section>
    );
};

export default Contact;

